import { useTranslation } from 'react-i18next';

const Title = ({ title , subTitle}) => {
  const { t } = useTranslation()

  return (
    <div className="title flex flex-col items-center justify-center py-10 lg:py-20">
      <h4 className='text-[#9b4819] px-4 font-medium text-[32px] md:text-[44px] lg:text-[52px] py-0 cursor-pointer'
      >{title}</h4>
      <p>{subTitle}</p>
    </div>
  )
}
export default Title