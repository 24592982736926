import './welcome.scss';
import Loge from '../assets/anciana_Logo.png';
import Character from '../../icons/Character';
import { useTranslation } from "react-i18next";


const Welcome = () => {
  const { t } = useTranslation()
  return (
    <div className="welcome flex px-3 justify-center items-center gap-20" id="home">
      <div className="flex flex-col items-center w-full md:w-2/3 lg:w-1/2 ">
        <img src={Loge} alt='Logo-icon' className=' w-[200px] md:w-[246px]' />
        <h1 className='text-[48px] lg:text-[56px] xl:text-[64px] font-bold'>{t('hallo')} <span className='text-[#9B4819]'>{t('iam')}</span></h1>
        <div className='text-[16px] sm:text-[18px] lg:text-[20px] xl:text-[22px] xxl:text-[24px] text-center mt-5 md:mt-8'
        >{t('welcome')}
        </div>
      </div>
      {/* <div className="hidden md:block">
        <Character></Character>
      </div> */}
    </div>
  )
}
export default Welcome; 