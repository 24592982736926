import './navbar.scss';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import i18next from 'i18next';
import 'flag-icon-css/css/flag-icons.min.css';
import { Link, useLocation } from 'react-router-dom';
import PersonIcon from './assets/person.png'
import Logo from '../icons/Logo';

const langauges = [
  {
    code: 'ar',
    name: 'العربيه',
    country_code: 'eg',
    dir: 'rtl',
  },
  {
    code: 'en',
    name: 'english',
    country_code: 'us',
    dir: 'ltr',
  },
];

const Navbar = () => {
  const location = useLocation();
  const { pathname } = location;
  const [showLangList, setShowLangList] = useState(false)
  const { t } = useTranslation();
  const currentLangCode = Cookies.get('i18next') || 'en';
  const token = Cookies.get('user');
  const currentLang = langauges.find(langauge => langauge.code === currentLangCode)

  useEffect(() => {
    document.title = t('app_title')
    console.log(Cookies.get('i18next'));
  }, [currentLang, t]);

  const [user, setUser] = useState()
  useEffect(() => {
    if (token) {
      const fetchData = async () => {
        try {
          const token = Cookies.get('user');
          if (!token) {
            // Handle case where token is not available
            console.log('User token not found');
            return;
          }

          const response = await fetch('https://back.ancianagroup.com/user/get_data', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          });

          const data = await response.json();
          if (data.success) {
            console.log(data.data)
            setUser(data.data); // Set user data in state
          } else {
            console.log(data)
            console.error('Error:', data.message);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };

      fetchData();
    }
  }, []);

  const handleLangList = (value, dir) => {
    document.body.dir = dir;
    i18next.changeLanguage(value);
    setShowLangList(false);
  }

  return (
    <div className="navbar py-4 px-1 sm:p-4 flex justify-between md:justify-around items-center bg-[#9b4819] sticky top-0 z-50">
      <Logo></Logo>

      <ul className="flex gap-3 md:gap-8 xl:gap-16 text-[14px] md:text-[16px] lg:text-[16px] text-white">
        {pathname === '/profile' ?
          <>
            <li><Link to='/'>{t('home')}</Link></li>
            <li><Link to='/'>{t('about_us')}</Link></li>
            <li><Link to='/'>{t('services')}</Link></li>
          </>
          :
          <>
            <li><a href='#home'>{t('home')}</a></li>
            <li><a href='#about'>{t('about_us')}</a></li>
            <li><a href='/#services'>{t('services')}</a></li>
          </>
        }
      </ul>

      <div className="relative w-[100px] md:w-[170px] flex justify-center" style={{ alignItems: "center" }}>
        <div className="hidden md:block">
          {token ?
            <Link to='/profile'>
              <img src={`https://back.ancianagroup.com/${user?.profilePicture}`} alt='user' style={{ borderRadius: "100%", width: "35px", height: "35px" }} />
            </Link>
            :
            <Link to='/login' className='text-white bg-[#bd8f53] rounded-[30px] py-1 md:py-2 px-2 md:px-5 text-[14px] md:text-[20px] lg:text-[16px]'>{t('login')}</Link>
          }
        </div>
        <div style={{ width: "10px" }}></div>
        <div className="" onClick={() => { setShowLangList(!showLangList) }}>
          <svg className='w-[20px] md:w-[27px]' viewBox="0 0 27 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0.833344L13.3333 14.1667L26.6667 0.833344H0Z" fill="white" />
          </svg>
        </div>
        {showLangList &&
          <ul className="list" id='list'>
            {token ? <Link to="/profile">
              <li className='flex items-center gap-1 border-b border-gray-400 md:hidden'>
                <img src={`https://back.ancianagroup.com/${user?.profilePicture}`} alt='user' style={{ borderRadius: "100%", width: "25px", height: "25px" }} />
                <span>{user?.name}</span>
              </li></Link> : <div></div>}
            {langauges.map(({ code, name, country_code, dir }) => {
              if (currentLangCode !== code) {
                return (
                  <li className='flex items-center justify-between flex-grow space-x-1 ' key={country_code}
                    onClick={() => handleLangList(code, dir)}>
                    <span className={`flag-icon flag-icon-${country_code}`}></span>
                    <span className=''>{name}</span>
                  </li>
                )
              }
            })
            }
            {token ? <Link to='/' onClick={() => {
              Cookies.remove("user")
            }}><li className='flex items-center justify-between border-t border-gray-400' >
                <span>{t('logout')}</span>
                <svg className='w-[16px] md:w-[21px]' viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.6465 13L19.6465 9M19.6465 9L15.6465 5M19.6465 9H5.64648M11.6465 13V14C11.6465 14.7956 11.3304 15.5587 10.7678 16.1213C10.2052 16.6839 9.44213 17 8.64648 17H4.64648C3.85083 17 3.08777 16.6839 2.52516 16.1213C1.96255 15.5587 1.64648 14.7956 1.64648 14V4C1.64648 3.20435 1.96255 2.44129 2.52516 1.87868C3.08777 1.31607 3.85083 1 4.64648 1H8.64648C9.44213 1 10.2052 1.31607 10.7678 1.87868C11.3304 2.44129 11.6465 3.20435 11.6465 4V5" stroke="#363232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </li></Link> : <li className='flex items-center justify-between border-t border-gray-400'>
              <Link to='/login'>
                <span>{t('login')}</span></Link>
            </li>}
          </ul>
        }
      </div>
    </div >
  )
}
export default Navbar;