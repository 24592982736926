import './profile.scss';
import Navbar from '../navbar/Navbar';
import Profileheader from './Profileheader';
import Profilebody from './Profilebody';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const Profile = () => {
  const [user, setUser] = useState(null); // Initialize user state to null
  const [loading, setLoading] = useState(true); // Initialize loading state to true

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get('user');
        if (!token) {
          // Handle case where token is not available
          console.log('User token not found');
          return;
        }

        const response = await fetch('https://back.ancianagroup.com/user/get_data', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        const data = await response.json();
        if (data.success) {
          console.log(data.data)
          setUser(data.data); // Set user data in state
        } else {
          console.log(data)
          console.error('Error:', data.message);
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false); // Set loading to false when data fetching is complete
      }
    };

    fetchData();
  }, []);

  // Render loading interface if loading state is true
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile">
      <Navbar></Navbar>
      <Profileheader name={user?.name} profilePicture={user?.profilePicture} coverPicture={user?.coverPicture}></Profileheader>
      <Profilebody user={user}></Profilebody>
    </div>
  )
}

export default Profile;
