'use client'
import './Login.scss';
import Image from './assets/image.png';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

const Signup = () => {
  const {t} = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate=useNavigate()
  const handleLogin = async (event) => {
    event.preventDefault(); // Prevent form submission

    try {
      const response = await fetch('https://back.ancianagroup.com/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      if (response.ok) {
        const token = data.token;
        Cookies.set('user', token, { expires: 7 }); // Set the 'user' cookie with token value, expires in 7 days
        navigate('/profile')
      } else {
        console.error('Error:', data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="signup flex  justify-center lg:justify-between">
      <div className="box lg:w-1/2 flex flex-col gap-2 items-center w-full justify-center">
        <h4 className='text-[#001f3f] mb-8 font-medium text-[40px]'>{t('the_login')}</h4>
        <form onSubmit={handleLogin} className='flex items-center  flex-col gap-3 w-full'>
          <div className="inp-box">
            <input
              type="email"
              name='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t('email')}
              required
            />
          </div>
          <div className="inp-box">
            <input
              type="password"
              name='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t('password')}
            />
          </div>
          <div className="submit-box mt-12 md:mt-20">
            <input
              type="submit"
              value={t('the_login')}
            />
          </div>
        </form>
        <div className="text-[20px] md:text-[24px] font-medium mt-12 md:mt-20">
          {t('hava_no_account')}
          <Link to='/signup' className='text-[#9b4819]'>{t('create_account')}</Link>
        </div>
      </div>
      <img src={Image} alt='image' className='w-1/2 hidden lg:block' />
    </div>
  );
}

export default Signup;
