import './about.scss';
import MobileImage from '../assets/mobile.png';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="about flex flex-col md:flex-row justify-evenly items-center relative px-32" id='about'>
      <div className="flex flex-col items-center md:items-start w-full md:w-2/3 lg:w-1/2 gap-4 sm:gap-16 md:gap-32">
        <h2 className='text-[48px] sm:text-[48px] md:text-[56px] lg:text-[86px] text-white text-center md:text-start'>
          {t('vision')}
        </h2>
        <button className='py-3 px-8 border text-white border-white rounded-full w-fit text-lg'>{t('mission')}</button>
      </div>
      <img src={MobileImage} alt='mobileImage' className='w-[180px] md:w-[220px] lg:w-[350px] rounded-[32px] lg:rounded-[52px]' />
    </div>
  )
}
export default About