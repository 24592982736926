import React from 'react'
import './scopes.scss'
import Scope1 from '../assets/scope1.png';
import Scope2 from '../assets/scope2.png';
import Scope3 from '../assets/scope3.png';
import { useTranslation } from 'react-i18next';

const Scopes = () => {
  const { t } = useTranslation();
  return (
    <div className="scopes flex justify-center">
      <div className="container  flex flex-col justify-center">
        <h4 className='text-[#9b4819] px-4 font-medium text-[32px] md:text-[44px] lg:text-[52px] py-0 cursor-pointer mb-10 text-center lg:mb-24' >
          {t('Scope_of_service')}
        </h4>
        <div className="flex justify-center flex-wrap items-start  gap-x-24 gap-y-10">
          <div className="flex flex-col justify-center items-center ">
            <div className="img">
              <img src={Scope3} alt='' />
            </div>
            <h5 className=' font-bold text-xl lg:text-3xl pt-3 pb-8 w-full text-center'
            >{t('Planning')}</h5>
            <p className='text-lg'>{t('retirement')}</p>
          </div>
          <div className=" w-fit flex flex-col justify-center items-center">
            <div className="img">
              <img src={Scope2} alt='' />
            </div>
            <h5 className=' font-bold text-xl lg:text-3xl pt-3 pb-8 w-full text-center'
            >{t('Longevity_Technology')}</h5>
            <p className='text-lg'>{t('telehealth')}</p>
          </div>
          <div className=" w-fit flex flex-col justify-center items-center">
            <div className="img">
              <img src={Scope1} alt='' />
            </div>
            <h5 className=' font-bold text-xl lg:text-3xl pt-3 pb-8 w-full text-center'
            >{t('Social_Engagement')}</h5>
            <p className='text-lg'>{t('communication')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Scopes