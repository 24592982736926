import './OurCenter.scss';
import Loge from '../assets/anciana_Logo.png';
import { useTranslation } from "react-i18next";
import React from 'react'

const OurCenter = () => {
  const { t } = useTranslation()
  return (
    <div className="ourcenter flex px-3 justify-center items-center gap-20" id="home">
      <div className="flex flex-col items-center w-full md:w-2/3 lg:w-1/2 ">
        <img src={Loge} alt='Logo-icon' className=' w-[200px] md:w-[246px]' />
        <h1 className='text-[40px] lg:text-[56px] xl:text-[64px] font-bold text-[#9B4819]'>{t('center')}</h1>
        <div className='text-[18px] sm:text-[22px] lg:text-[24px] xl:text-[28px] xxl:text-[32px] text-center mt-5 md:mt-8'
        >{t('center_p')}
        </div>
      </div>
    
    </div>
  )
}

export default OurCenter;