import Service from './Service';
import Title from './Title';
import './services.scss';
import Service1 from '../assets/service1.jpeg';
import Service2 from '../assets/service2.jpeg';
import Service3 from '../assets/service3.jpeg';
import Servicemore from './Servicemore';
import Image1 from '../assets/image1.png';
import Image2 from '../assets/image2.png';
import Image3 from '../assets/image3.png';
import Image4 from '../assets/image4.png';
import Image5 from '../assets/image5.png';
import Image6 from '../assets/image6.png';
import Value from './Value';
import Download from './Download';
import { useTranslation } from 'react-i18next';
import ValueImg1 from '../assets/value1.jpeg';
import ValueImg2 from '../assets/value2.jpeg';
import ValueImg3 from '../assets/value3.jpeg';
import ValueImg4 from '../assets/value4.jpeg';
import ValueImg5 from '../assets/value5.jpeg';

const Services = () => {
  const { t } = useTranslation();
  return (
    <div className="services" id='services'>
      <Title title={t('brand_objectives')} subTitle={t('successful_aging')}></Title>
      <div className="flex justify-center">
        <div className="container flex justify-around flex-wrap gap-y-8 ">
          <Service image={Service2} name={t('create')}></Service>
          <Service image={Service1} name={t('influence')}></Service>
          <Service image={Service3} name={t('reposition')}></Service>
        </div>
      </div>
      <div className="py-16 mt-12 lg:mt-20">
            <h4 className='text-[#9b4819] px-4 font-medium lg:hidden text-[32px] md:text-[44px] lg:text-[52px] py-0 cursor-pointer mb-10 text-center' >
              {t('brand_values')}
            </h4>
        <div className="flex justify-center">
          <div className="container flex justify-evenly items-end flex-wrap gap-y-8 ">
            <Value value={t('independence')} photo={ValueImg2}></Value>
            <h4 className='text-[#9b4819] px-4 font-medium hidden lg:block text-[32px] md:text-[44px] lg:text-[52px] py-0 cursor-pointer' >
              {t('brand_values')}
            </h4>
            <Value value={t('respect')} photo={ValueImg1}></Value>
          </div>
        </div>
        <div className="flex justify-center mt-8">
          <div className="container flex items-start justify-evenly flex-wrap gap-y-6 gap-x-5  ">
            <Value value={t('capability')} photo={ValueImg5}></Value>
            <div className="md:mt-12">
            <Value value={t('empowerment')} photo={ValueImg4}></Value>
            </div>
            <Value value={t('inclusivity')} photo={ValueImg3}></Value>
          </div>
        </div>
      </div>
      <Download></Download>
    </div>
  )
}
export default Services;