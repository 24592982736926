import { useTranslation } from 'react-i18next';
import './footer.scss';
import FacebooKIcon from './assets/facebook.png';
import TwitterIcon from './assets/twitter.png';
import InstagramIcon from './assets/instagram.png';
import LinkedinIcon from './assets/linkedin.png';
import EmailIcon from './assets/email.png';
import Contact from './assets/contact.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation()
  return (
    <footer className='flex justify-center bg-[#9B4819] text-white py-10 md:py-20'>
      <div className="container">
        <p className='md:w-1/2 text-[48px] pb-5 border-b border-[#BD8F53]'>
          {t('footer_content')}
        </p>
        <p className='my-4'>{t('footer_visit')}</p>
        <div className="flex gap-2">
          <Link to={"https://www.facebook.com/ancianagroup"} target="_blank">
            <img src={FacebooKIcon} alt='' width={35} className='rounded-full' />
          </Link>
          {/*<Link to={"https://www.facebook.com/ancianagroup"} target="_blank">
            <img src={TwitterIcon} alt='' width={35} className='rounded-full' />
          </Link>*/}
          <Link to={"https://www.instagram.com/ancianagroup"} target="_blank">
            <img src={InstagramIcon} alt='' width={32} className='' />
          </Link>
          <Link to={"https://www.linkedin.com/company/ancianagroup"} target="_blank">
            <img src={LinkedinIcon} alt='' width={32} height={32} className='' />
          </Link>
        </div>
        <div className="flex flex-col mt-5 items-end gap-3 justify-end">
          <div className="lg:w-1/3 space-y-5">
            <div className="flex items-center gap-3">
              <img src={Contact} alt='' width={75} />
              <div className="flex flex-col gap-3">
                <p>{t('footer_phone')}</p>
                <p>0225173934 / 01555667797</p>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <img src={EmailIcon} alt='' width={75} />
              <div className="flex flex-col gap-3">
                <p>{t('footer_email')}</p>
                <p>info@ancianagroup.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
