import { useEffect, useState } from "react";
import Item from "./Item";
import Cookies from 'js-cookie';
import { useTranslation } from "react-i18next";

const Profilebody = ({ user }) => {
  const [name, setName] = useState('');
  const [phone, setphone] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [date, setDate] = useState('');
  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const token = Cookies.get('user');
      const response = await fetch('https://back.ancianagroup.com/user/updateinfo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          name,
          phone,
          email,
          gender,
          dateOfBirth: date,
        }),
      });
      const data = await response.json();
      console.log(data); // Handle response data as needed
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setphone(user.phone);
      setGender(user.gender);
      setDate(user.dateOfBirth.split("T")[0]);
    }
  }, [user]);

  return (
    <div className="profile-body flex justify-center py-[100px] sm:py-[120px] md:pt-[170px] lg:pt-[250px]">
      <div className="container px-2">
        <h3 className='text-[#001f3f] text-[20px] xxl:mx-32 sm:text-[20px] md:text-[24px] lg:text-[32px] py-2 w-fit border-b border-black'>{t('personal_information_and_security')}</h3>
        <form onSubmit={handleSubmit} className="w-full mt-12 xxl:px-32">
          <div className="flex justify-between flex-wrap gap-y-5">
            <Item title={t('name')} value={name} setvalue={setName}></Item>
            <Item title={t('phone')} value={phone} setvalue={setphone}></Item>
            <Item title={t('email')} inptype="email" value={email} setvalue={(e) => { }}></Item>
            <div className="item w-full md:w-fit">
              <h4 className="text-[20px] mb-3">{t('gender')}</h4>
              <div className="inp-box">
                <select
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  placeholder={t('gender')}
                  className="w-full bg-transparent outline-none"
                >
                  <option value="Male">{t('male')}</option>
                  <option value="Female">{t('female')}</option>
                  <option value="Prefer not to say">{t('prefer_not_to_say')}</option>
                </select>
              </div>
            </div>
            <Item title={t('date')} inptype="date" value={date} setvalue={setDate}></Item>
          </div>
          <div className="flex justify-end mt-12 md:mt-20">
            <div className="submit-box cursor-pointer">
              <input type="submit" value={t('save_changes')} className="text-[20px] md:text-[24px]" />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profilebody;
