import axios from 'axios';
import './signup.scss';
import Image from './assets/image.png';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Signup = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [Q, setQ] = useState('');
  const [A, setA] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [gender, setGender] = useState('Male');
  const [governorates, setGovernorates] = useState([]);
  const [selectedGovernorate, setSelectedGovernorate] = useState('');
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    // Fetch the list of governorates
    const fetchGovernorates = async () => {
      try {
        const response = await axios.get('https://back.ancianagroup.com/governorate');
        setGovernorates(response.data.response);
        setSelectedGovernorate(response.data.response[0])
        // Assuming the response is an array of governorates
      } catch (error) {
        console.error('Error fetching governorates:', error);
      }
    };

    fetchGovernorates();
  }, []);

  const handleGovernorateChange = async (selectedGovernorate) => {
    setSelectedGovernorate(selectedGovernorate);

  };
  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get(`https://back.ancianagroup.com/city/${selectedGovernorate._id}`);
        setCities(response.data.response);
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };

    if (selectedGovernorate) {
      fetchCities();
    }
  }, [selectedGovernorate]);
  const handleSignup = async (e) => {
    e.preventDefault();
    console.log(selectedCity)
    const user = {
      name,
      email,
      phone,
      password,
      confirmPassword,
      question: Q,
      answer: A,
      gender,
      governorate: selectedGovernorate._id,
      city: selectedCity,
      dateOfBirth: "1990-01-01"
    };

    try {
      const response = await axios.post('https://back.ancianagroup.com/user/sign_up', user);
      if (response.data.success) {

        navigate('/')
      } else {
        // Signup failed, display error message
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      console.error('Error signing up:', error);
      setErrorMessage('An error occurred during signup');
    }
  };

  return (
    <div className="signup flex justify-center lg:justify-between " style={{ overflow: "hidden", display: "flex", flexDirection: "row" }}>
      <div className="box lg:w-1/2 flex flex-col gap-2 items-center w-full py-12" style={{ overflow: "auto" }}>
        <h4 className='text-[#001f3f] mb-8 font-medium text-[40px]'>{t('the_signup')}</h4>
        <form onSubmit={handleSignup} className='flex items-center flex-col gap-3 w-full'>
          <div className="inp-box">
            <input
              type='text'
              name='name'
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={t('name')}
            />
          </div>
          <div className="inp-box">
            <input
              type="email"
              name='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t('email')}
              required
            />
          </div>
          <div className="inp-box">
            <input
              type='text'
              name='phone'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder={t('phone')}
            />
          </div>
          <div className="inp-box">
            <select
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              placeholder={t('gender')}
            >
              <option value="Male">{t('male')}</option>
              <option value="Female">{t('female')}</option>
              <option value="Prefer not to say">{t('prefer_not_to_say')}</option>
            </select>
          </div>
          <div className="inp-box">
            <select
              value={selectedGovernorate}
              onChange={(e) => handleGovernorateChange(e.target.value)}
              placeholder={t('governorate')}
            >
              {governorates.map(governorate => (
                <option key={governorate._id} value={governorate._id}>{governorate.name}</option>
              ))}
            </select>
          </div>
          <div className="inp-box">
            <select
              value={selectedCity}
              onChange={(e) => setSelectedCity(e.target.value)}
              placeholder={t('city')}
            >
              <option value='choose city'>{t('choose_city')}</option>
              {cities.map(city => (
                <option key={city._id} value={city._id}>{city.name}</option>
              ))}
            </select>
          </div>
          <div className="inp-box">
            <input
              type="password"
              name='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t('password')}
            />
          </div>
          <div className="inp-box">
            <input
              type='password'
              name='confirmPass'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder={t('confirm_password')}
            />
          </div>
          <div className="inp-box">
            <input
              type='text'
              value={Q}
              onChange={(e) => setQ(e.target.value)}
              placeholder={t('security_question')}
            />
          </div>
          <div className="inp-box">
            <input
              type='text'
              value={A}
              onChange={(e) => setA(e.target.value)}
              placeholder={t('answer')}
            />
          </div>
          <div className="submit-box mt-12 md:mt-20">
            <input type="submit" value={t('the_signup')} />
          </div>
        </form>
        <div className="text-[20px] md:text-[24px] font-medium mt-12 md:mt-20">
          {errorMessage && <p className="text-red-600">{errorMessage}</p>}
          <span>{t('have_account')}</span>
          <Link to='/login' className='text-[#9b4819]'> {t('enter_account')} </Link>
        </div>
      </div>
      <img src={Image} alt='image' className='w-1/2 hidden lg:block' />
    </div>
  )
}
export default Signup;
