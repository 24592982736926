const Item = ({ title, inptype = 'text', value, setvalue }) => {
  return (
    <div className="item w-full md:w-fit">
      <h4 className="text-[20px] mb-3">{title}</h4>
      <div className="inp-box">
        <input
          type={inptype}
          name={value}
          value={value}
          onChange={(e) => setvalue(e.target.value)}
        />
      </div>
    </div>
  )
}
export default Item;