import BackImage from './assets/back.png';
import UserImage from './assets/user.png';

const Profileheader = ({name,profilePicture,coverPicture}) => {
  return (
    <div className="profile-header">
      <img src={`https://back.ancianagroup.com/${coverPicture}`} alt={BackImage} className='w-full h-28 sm:h-44 md:h-60 lg:h-80' />
      <div className="user-desc flex flex-col items-center gap-2 lg:gap-5 top-[60px] sm:top-[100px] md:top-[140px]">
        <div className="user-img w-[100px] h-[100px] sm:w-[140px] sm:h-[140px] md:w-[180px] md:h-[180px] lg:w-[330px] lg:h-[330px] rounded-full">
          <img src={`https://back.ancianagroup.com/${profilePicture}`} alt={UserImage} className='w-[100px] h-[100px] sm:w-[140px] sm:h-[140px] md:w-[180px] md:h-[180px] lg:w-[330px] lg:h-[330px]' style={{ borderRadius: "50%" }} />
          <div className='flex items-center justify-center w-[23px] h-[23px] sm:w-[30px] sm:h-[30px] md:w-[40px] md:h-[40px] lg:w-[50px] lg:h-[50px] left-[5px] bottom-[5px] lg:left-[20px] lg:bottom-[25px] '>
            <svg className='w-[15px] sm:w-[20px] md:w-[25px] lg:w-[35px] ' viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.02016 32.8666H30.9794C34.1133 32.8666 35.7108 31.2993 35.7108 28.1956V12.4205C35.7108 9.31678 34.1133 7.76493 30.9794 7.76493H27.4539C26.2788 7.76493 25.9175 7.52386 25.2393 6.77043L24.0185 5.414C23.2805 4.586 22.5123 4.13343 20.945 4.13343H14.9484C13.3966 4.13343 12.6277 4.586 11.8749 5.414L10.6542 6.77043C9.99137 7.50843 9.61466 7.76493 8.43951 7.76493H5.01951C1.88559 7.76493 0.288086 9.31678 0.288086 12.4205V28.1956C0.288086 31.2993 1.88623 32.8666 5.02016 32.8666ZM17.9924 28.0451C13.4416 28.0451 9.78051 24.3834 9.78051 19.8037C9.78051 15.2227 13.4416 11.5623 17.9917 11.5623C22.5721 11.5623 26.2184 15.2227 26.2184 19.8037C26.2184 24.3834 22.5573 28.0451 17.9917 28.0451M28.8245 16.1266C27.7998 16.1266 26.9564 15.2986 26.9564 14.2739C26.9564 13.7784 27.1532 13.3032 27.5035 12.9529C27.8539 12.6025 28.329 12.4057 28.8245 12.4057C29.32 12.4057 29.7951 12.6025 30.1455 12.9529C30.4958 13.3032 30.6927 13.7784 30.6927 14.2739C30.6927 15.2986 29.8492 16.1266 28.8245 16.1266ZM17.9924 25.7553C18.7742 25.7562 19.5485 25.6029 20.271 25.3041C20.9935 25.0054 21.65 24.567 22.2028 24.0142C22.7557 23.4613 23.194 22.8049 23.4928 22.0824C23.7916 21.3599 23.9449 20.5855 23.9439 19.8037C23.9448 19.0219 23.7914 18.2477 23.4926 17.5252C23.1938 16.8028 22.7554 16.1464 22.2026 15.5937C21.6498 15.0409 20.9933 14.6026 20.2709 14.3039C19.5484 14.0051 18.7741 13.8519 17.9924 13.8528C14.7074 13.8528 12.0562 16.5046 12.0562 19.8037C12.0562 23.1035 14.7228 25.7553 17.9924 25.7553Z" fill="#6D6D6D" />
            </svg>
          </div>
        </div>
        <h2 className='text-[#001f3f] text-[20px] md:text-3xl lg:text-[40px] font-medium'>{name}</h2>
      </div>
    </div>
  )
}
export default Profileheader