import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import About from "./about/About";
import OurCenter from "./ourcenter/OurCenter";
import Scopes from "./scopes/Scopes";
import Services from "./services/Services";
import Welcome from "./welcome/Welcome";

const Home = () => {
  return (
    <>
      <Navbar></Navbar>
      <Welcome></Welcome>
      <About></About>
      <OurCenter></OurCenter>
      <Scopes></Scopes>
      <Services></Services>
      <Footer></Footer>
    </>
  )
}
export default Home;