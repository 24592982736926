import Image from '../assets/iPhoe.png';
import AppstoreIcon from '../assets/appstore.png';
import GoogleplayIcon from '../assets/gooolepaly.png';
import { useTranslation } from 'react-i18next';

const Download = () => {
  const { t } = useTranslation()
  return (
    <div className="download flex flex-col lg:flex-row items-center justify-center py-10">
      <img src={Image} alt='iphone' className='md:w-[640px] xl:w-[900px]' />
      <div className="flex flex-col items-center gap-3 ">
        <p className='text-[32px] lg:text-[40px] font-medium text-center'>{t('dwonload_now')}</p>
        <p className='text-[24px] lg:text-[32px] font-medium'>{t('available_now')}</p>
        <a >
          <img src={AppstoreIcon} alt="appstore" className='w-[250px] lg:w-[342px]' />
        </a>
        <a href='https://play.google.com/store/apps/details?id=com.xcodecompany.anciana' target='_blank'>
          <img src={GoogleplayIcon} alt="googleplay" className='w-[250px] lg:w-[342px]' />
        </a>
      </div>
    </div>
  )
}
export default Download;